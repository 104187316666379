<template>
    <section class="hero is-fullheight">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-4-tablet is-4-desktop is-4-widescreen">
              <form @submit.prevent="handleLogin" class="box">
                <h1 class="title">Login</h1>
                <div class="field">
                  <label class="label">Email</label>
                  <div class="control has-icons-left">
                    <input
                      v-model="email"
                      class="input"
                      type="email"
                      placeholder="e.g. user@example.com"
                      required
                    >
                    <span class="icon is-small is-left">
                      <i class="fas fa-envelope"></i>
                    </span>
                  </div>
                </div>
                <div class="field">
                  <label class="label">Password</label>
                  <div class="control has-icons-left">
                    <input
                      v-model="password"
                      class="input"
                      type="password"
                      placeholder="********"
                      required
                    >
                    <span class="icon is-small is-left">
                      <i class="fas fa-lock"></i>
                    </span>
                  </div>
                </div>
                <div class="field">
                  <button class="button is-primary" type="submit">Login</button>
                </div>
                <p class="help is-danger" v-if="error">{{ error }}</p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>

  <script>
  import { useAuth } from '../store';

  export default {
    name: 'LoginComponent',
    data() {
      return {
        email: '',
        password: '',
        error: '',
      };
    },
    setup() {
      const { login } = useAuth();
      return { login };
    },
    methods: {
      handleLogin() {
        this.error = '';
        // Simulate an API call for login
        const envEmail = process.env.VUE_APP_USER_EMAIL;
        const envPassword = process.env.VUE_APP_USER_PASSWORD;
        if (this.email === envEmail && this.password === envPassword) {
          this.login();
          alert('Login successful!');
          // Redirect to the main page or set a token, etc.
          this.$router.push('/');
        } else {
          this.error = 'Invalid credentials. Please try again.';
        }
      },
    },
  };
  </script>

  <style scoped>
  .hero-body {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .box {
    width: 100%;
    max-width: 400px;
  }
  .title {
    text-align: center;
  }
  .help {
    text-align: center;
  }
  </style>
