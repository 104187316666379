// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import { useAuth } from '../store';
import LoginComponent from '@/components/LoginComponent.vue';
import MainComponent from '@/components/MainComponent.vue';

const routes = [
  { path: '/login', component: LoginComponent },
  {
    path: '/',
    component: MainComponent,
    beforeEnter: (to, from, next) => {
      const { state } = useAuth();
      if (state.isAuthenticated) {
        next();
      } else {
        next('/login');
      }
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
