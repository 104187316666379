<template>
  <section class="section py-2">
    <div class="container">
      <!-- Chatbot widget -->
      <div id="chatbox" class="box">
        <div v-for="message in messages" :class="['chat-message', message.type]" :key="message.id">
          <!-- <div class="message-container" v-html="message.content"></div> -->
          <div class="message-container">
            <div v-if="message.content.details">
              <h2>📚 Here are the details for Incident ID: {{ message.content.details.incident_id }}</h2>
              <button class="button is-black is-small is-static">Users</button>
              <p>{{ message.content.details.risky_users }}</p>
              <button class="button is-black is-small is-static">Violation</button>
              <p>{{ message.content.details.violation }}</p>
              <button class="button is-black is-small is-static">intentions</button>
              <div v-for="(intention, index) in message.content.details.intentions" :key="intention">
                <p>{{index+1}}. {{intention}}</p>
              </div>
              <button class="button is-black is-small is-static">Activities</button>
              <div v-for="(item, index) in message.content.details.activities" :key="index">
                <p>[{{ item.timestamp }}] [{{ item.method }}] {{ item.activity }}</p>
              </div>
              <div v-for="(entry, index) in message.content.details.raw_data" :key="index">
                <raw-data-card :raw-data="entry" :index="index" />
              </div>
            </div>
            <div v-else-if="message.content.img_url_array && message.content.img_url_array.length">
                <button class="button is-black is-small is-static">Screenshots</button>
                <div v-for="(img, index) in message.content.img_url_array" :key="index">
                  <img :src="img.url" :alt="'Screenshot ' + (index + 1)" />
                  <div v-html="simpleMarkdownToHtml(img.description)"></div>
                </div>
            </div>
            <div v-else v-html="message.content"></div>
          </div>
          <div class="button-container" v-if="message.buttons">
            <button v-for="button in message.buttons" :key="button.label" class="button is-primary mx-1" @click="handleButtonClick(button.action, button.incident_id)">
              {{ button.label }}
            </button>
          </div>
        </div>
        <!-- Scroll to top button -->
        <button v-if="showScrollTopButton" @click="scrollToTop" class="scroll-to-top">
          <i class="fas fa-arrow-up"></i>
        </button>
        <!-- Loading indicator -->
        <div v-if="isLoading" class="has-text-centered">
          <button class="button is-loading is-white is-large"></button>
        </div>
      </div>
      <!-- Chatbot CLI -->
      <div class="field has-addons is-centered mt-4">
        <div class="control is-expanded">
          <input
            v-model="userInput"
            class="input"
            type="text"
            placeholder="Type a message..."
            @keyup.enter="sendUserMessage"
            :disabled="isLoading"
          />
        </div>
        <div class="control">
          <button @click="sendUserMessage" class="button is-primary" :disabled="isLoading">Send</button>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal" :class="{ 'is-active': isModalActive }">
      <div class="modal-background" @click="closeModal"></div>
      <div class="modal-content">
        <div class="box">
          <table class="table is-fullwidth">
            <thead class="is-centered">
              <tr>
                <th>User</th>
                <th>Incident ID</th>
                <th>Violation</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="incident in incidents" :key="incident.incident_id">
                <td>{{ incident.risky_users }}</td>
                <td>{{ incident.incident_id }}</td>
                <td class="has-text-left">{{ incident.violation }}</td>
                <td><button class="button is-small is-primary" @click="checkDetails(incident.incident_id)">Show Details</button></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <button class="modal-close is-large" aria-label="close" @click="closeModal"></button>
    </div>
  </section>
</template>

<script>
import { ref, onMounted, nextTick } from 'vue';
import RawDataCard from './RawDataCard.vue';

export default {
  name: 'ChatbotComponent',
  components: {
    RawDataCard
  },
  setup() {
    const messages = ref([]);
    const userInput = ref('');
    const incidents = ref([]);
    const isModalActive = ref(false);
    const currentIncident = ref(0);
    const isLoading = ref(false);
    const showScrollTopButton = ref(true);

    onMounted(() => {
      sendMessage('welcome');
    });

    function sendMessage(action, incident_id = null) {
      fetch('/api/chatbot', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ action, incident_id }),
      })
      .then((response) => response.json())
      .then((data) => {
        if (action === 'welcome') {
          addMessage(data.message, 'system', data.buttons);
        } else if (action === 'show_incidents') {
          incidents.value = data.incidents;
          isModalActive.value = true;
        } else if (action === 'incident_details') {
          currentIncident.value = data.details.incident_id;
          addMessage(`Show details for Incident ID: ${currentIncident.value}`, 'user');
          const detailsMessage = {
            type: 'incident_details',
            details: {
              incident_id: data.details.incident_id,
              risky_users: data.details.risky_users,
              violation: data.details.violation,
              activities: JSON.parse(data.details.activities.replace(/'/g, '"')),
              intentions: JSON.parse(data.details.intentions.replace(/'/g, '"')),
              raw_data: JSON.parse(data.details.raw_data),
            }
          };
          addMessage(detailsMessage, 'system', data.buttons);
        } else if (action === 'notify_leader') {
          addMessage(`Notify leader for Incident ID: ${currentIncident.value}`, 'user');
          addMessage(data.message, 'system');
        } else if (action === 'show_screenshot') {
          addMessage(`Show screenshot for Incident ID: ${currentIncident.value}`, 'user');
          if (data.img_url_array) {
            let imgUrlArray = data.img_url_array;
            for (let i = 0; i < imgUrlArray.length; i ++ ){
              let url = imgUrlArray[i].url;
              imgUrlArray[i].url = url.replace(/'/g, '"');
            }
            addMessage({img_url_array: imgUrlArray}, 'system');
          } else {
            addMessage(data.error, 'system');
          }
        }
      });
    }

    function addMessage(content, type, buttons = []) {
      messages.value.push({
        id: messages.value.length,
        content: content,
        type,
        buttons,
      });
      nextTick(() => {
        const chatbox = document.getElementById('chatbox');
        chatbox.scrollTop = chatbox.scrollHeight;
        checkScroll();
      });
    }

    function sendUserMessage() {
      if (isLoading.value) return;  // Block user input during loading
      if (!userInput.value) return;
      const user_message = userInput.value;
      addMessage(user_message, 'user');
      sendToLLM(user_message, messages.value);
      userInput.value = '';
    }

    function handleButtonClick(action, incident_id) {
      if (action === 'download_report') {
        addMessage(`Download report for Incident ID: ${currentIncident.value}`, 'user');
        downloadReport(incident_id);
      } else {
        sendMessage(action, incident_id);
      }
    }

    function downloadReport(incident_id) {
      fetch('/api/download_report', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ incident_id }),
      })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `incident_report_${incident_id}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((error) => {
        console.error('Error downloading report:', error);
      });
    }

    function checkDetails(incident_id) {
      sendMessage('incident_details', incident_id);
      isModalActive.value = false;
    }

    function closeModal() {
      isModalActive.value = false;
    }

    function handleScroll(event) {
      const chatbox = event.target;
      showScrollTopButton.value = chatbox.scrollTop > chatbox.clientHeight;
    }

    function checkScroll() {
      const chatbox = document.getElementById('chatbox');
      if (chatbox.scrollHeight > chatbox.clientHeight) {
        showScrollTopButton.value = true;
      } else {
        showScrollTopButton.value = false;
      }
    }

    function scrollToTop() {
      const chatbox = document.getElementById('chatbox');
      chatbox.scrollTo({ top: 0, behavior: 'smooth' });
    }

    function simpleMarkdownToHtml(markdown) {
      let html = markdown;

      // Convert headers
      html = html.replace(/^### (.*$)/gim, '<h3>$1</h3>');
      html = html.replace(/^## (.*$)/gim, '<h2>$1</h2>');
      html = html.replace(/^# (.*$)/gim, '<h1>$1</h1>');

      // Convert bold text
      html = html.replace(/\*\*(.*)\*\*/gim, '<b>$1</b>');

      // Convert italic text
      html = html.replace(/\*(.*)\*/gim, '<i>$1</i>');

      // Convert links
      html = html.replace(/\[(.*?)\]\((.*?)\)/gim, '<a href="$2">$1</a>');

      // Convert line breaks
      html = html.replace(/\n/gim, '<br>');

      return html.trim();
    }

    async function sendToLLM(user_message, message_logs) {
      isLoading.value = true;  // Set loading state to true
      try {
        const response = await fetch('/api/interact_with_llm', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            user_input: user_message,
            message_logs: message_logs.map(msg => JSON.stringify(msg.content))
          }),

        });
        const data = await response.json();
        if (data.response) {
          addMessage(`<pre>${data.response}</pre>`, 'system');
        } else {
          addMessage('Error: Unable to get response from LLM', 'system');
        }
      } catch (error) {
        addMessage(`Error: ${error}`, 'system');
      } finally {
        isLoading.value = false;  // Set loading state to false
      }
    }

    return {
      messages,
      userInput,
      incidents,
      isModalActive,
      isLoading,
      sendUserMessage,
      handleButtonClick,
      checkDetails,
      closeModal,
      showScrollTopButton,
      handleScroll,
      scrollToTop,
      simpleMarkdownToHtml
    };
  },
};
</script>

<style scoped>
/* Chatbox Styles */

#chatbox {
  background-color: #111827;
  border: 1px solid #dbdbdb;
  padding: 1em;
  border-radius: 6px;
  height: 400px;
  overflow-y: auto;
}

.chat-message {
  max-width: 60%;
  padding: 1em;
  border-radius: 6px;
  margin-bottom: 10px;
  word-wrap: break-word;
  border: 1px solid #dbdbdb;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
}

.chat-message.system {
  background-color: #111827;
  border-left: 3px solid #209cee;
  text-align: left;
  margin-right: auto;
}

.chat-message.user {
  background-color: #111827;
  border-right: 3px solid #ffdd57;
  text-align: right;
  margin-left: auto;
}
/* Button Container */
.button-container {
  margin-top: 10px;
  text-align: left;
}

/* Loading indicator */
.has-text-centered .button.is-loading {
  border: none;
  background: none;
}

.button.is-loading.is-white.is-large {
  width: 48px;
  height: 48px;
  border: 3px solid #00d1b2;  /* Primary color from Bulma */
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.chat-message .message-container pre {
  white-space: pre-wrap;       /* CSS3 */
  word-wrap: break-word;       /* IE */
  word-break: break-word;      /* Modern browsers */
  max-width: 100%;             /* Ensure it doesn't exceed the container width */
  overflow-wrap: break-word;   /* Break long words */
}

/* Scroll to top button */
.scroll-to-top {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #00d1b2;
  color: #fff;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.scroll-to-top:hover {
  background-color: #00b09c;
}
</style>
