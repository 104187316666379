<template>
    <div :class="themeClass">
      <div class="container">
        <h1 class="title">🚀 AI POC</h1>
        <EventPipeWidget />
        <ChatbotComponent />
      </div>
    </div>
</template>
<script>
import { ref, onMounted } from 'vue';
import EventPipeWidget from './EventPipeWidget.vue';
import ChatbotComponent from './ChatbotComponent.vue';

export default {
    name: 'MainComponent',
    components: {
        EventPipeWidget,
        ChatbotComponent,
    },
    setup() {
        const theme = ref('dark-mode');

        onMounted(() => {
        const savedTheme = localStorage.getItem('theme') || 'dark-mode';
        theme.value = savedTheme;
        document.body.className = savedTheme;
        });

        const toggleTheme = () => {
        theme.value = theme.value === 'dark-mode' ? 'light-mode' : 'dark-mode';
        document.body.className = theme.value;
        localStorage.setItem('theme', theme.value);
        };

        return {
        themeClass: theme,
        toggleTheme,
        };
    },
};
</script>

<style scoped>
</style>
