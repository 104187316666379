<template>
  <section class="section py-2">
    <div class="container">
      <div class="event-pipe-widget box">
        <div ref="chartRef" class="chart"></div>
      </div>
    </div>
  </section>
</template>

<script>
import * as echarts from 'echarts';

export default {
    name: 'EventPipeWidget',
    data() {
        return {
        chart: null,
        incidentCount: 0,
        eventCount: 0,
        dataPoints: [],
        maxDataPoints: 10,
        fetchInterval: null,
        };
    },
    mounted() {
        this.initializeChart();
        this.fetchData();
        this.fetchInterval = setInterval(this.fetchData, 3000);
    },
    beforeUnmount() {
        if (this.fetchInterval) clearInterval(this.fetchInterval);
        if (this.chart) this.chart.dispose();
    },
    methods: {
        initializeChart() {
            this.chart = echarts.init(this.$refs.chartRef);
            const option = {
                title: {
                    text: `Incident Counts / Event Counts: ${this.incidentCount} / ${this.eventCount}`,
                    left: 'center',
                    textStyle: {
                        color: 'white'
                    }
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['Event Count', 'Incident Count'],
                    right: '10%',
                    textStyle: {
                        color: 'white'
                    }
                },
                xAxis: {
                type: 'category',
                boundaryGap: false,
                data: []
                },
                yAxis: {
                    type: 'value',
                    splitLine: { show: false }
                },
                series: [
                {
                    name: 'Event Count',
                    type: 'line',
                    data: [],
                    itemStyle: {
                    color: 'green'
                    }
                },
                {
                    name: 'Incident Count',
                    type: 'line',
                    data: [],
                    itemStyle: {
                    color: 'red'
                    }
                }
                ]
            };
            this.chart.setOption(option);
            },
            async fetchData() {
        try {
            const response = await fetch('/api/event_counts');
            const data = await response.json();
            const currentTime = new Date().toLocaleTimeString();
            this.addDataPoint(currentTime, data.event_count, data.incident_count);
            this.updateChart();
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        },
        addDataPoint(label, eventCountValue, incidentCountValue) {
        this.dataPoints.push({ label, eventCount: eventCountValue, incidentCount: incidentCountValue });
        if (this.dataPoints.length > this.maxDataPoints) {
            this.dataPoints.shift();
        }
        this.eventCount = this.dataPoints[this.dataPoints.length - 1].eventCount;
        this.incidentCount = this.dataPoints[this.dataPoints.length - 1].incidentCount;
        },
        updateChart() {
        if (!this.chart) return;

        const labels = this.dataPoints.map(dp => dp.label);
        const eventData = this.dataPoints.map(dp => dp.eventCount);
        const incidentData = this.dataPoints.map(dp => dp.incidentCount);

        this.chart.setOption({
            title: {
            text: `Incidents: ${this.incidentCount} / Events: ${this.eventCount}`
            },
            xAxis: {
            data: labels
            },
            series: [
            {
                name: 'Event Count',
                data: eventData
            },
            {
                name: 'Incident Count',
                data: incidentData
            }
            ]
        });
        }
    }
};
</script>

<style scoped>
.event-pipe-widget {
width: 100%;
background-color: #111827;
border: 1px solid #dbdbdb;
padding: 1em;
border-radius: 6px;
margin-bottom: 20px;
max-height: 200px;
}

.chart {
width: 100%;
height: 200px;
}
</style>
