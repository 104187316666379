<template>
    <div class="card my-3">
        <header class="card-header">
        <p class="card-header-title">
            Raw Data Entry {{ index + 1 }}
        </p>
        <button class="card-header-icon" aria-label="more options" @click="toggleCard">
            <span class="icon">
            <i :class="iconClass" aria-hidden="true"></i>
            </span>
        </button>
        </header>
        <div class="card-content" v-show="isVisible">
        <div class="content">
            <div v-for="(value, key) in rawData" :key="key" class="field">
            <label class="label">{{ key }}</label>
            <div class="control">
                <input class="input" type="text" :value="value" readonly>
            </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RawDataCard',
    props: {
        rawData: {
        type: Object,
        required: true
        },
        index: {
        type: Number,
        required: true
        }
    },
    data() {
        return {
        isVisible: false
        };
    },
    computed: {
        iconClass() {
        return this.isVisible ? 'fas fa-angle-up' : 'fas fa-angle-down';
        }
    },
    methods: {
        toggleCard() {
        this.isVisible = !this.isVisible;
        }
    }
    };
</script>

<style scoped>
.card {
    margin-bottom: 1rem;
}
</style>
