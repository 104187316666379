// src/store.js
import { reactive } from 'vue';

const state = reactive({
  isAuthenticated: false,
});

export const useAuth = () => {
  const login = () => {
    state.isAuthenticated = true;
  };

  const logout = () => {
    state.isAuthenticated = false;
  };

  return {
    state,
    login,
    logout,
  };
};
